.skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 14px;
  background-color: #e5e5e5;
  overflow: hidden;

  .skeleton_highlight {
    position: absolute;
    top: -50%;
    left: 0;
    height: 300%;
    width: 30%;
    max-width: 100px;
    filter: blur(1.6rem);
    animation-name: highlightAnimate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    background-color: var(--white-color);
    z-index: 2;
    // transform: rotate(5deg);
  }

  @keyframes highlightAnimate {
    0% {
      left: -30%;
    }

    50% {
      left: 110%;
    }

    100% {
      left: -10%;
    }
  }
}