@import "/src/styles/mixin";


.code_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 7px;
  margin: 12px auto 24px;
  height: 40px;

  .input {
    padding: 9px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-light-third);
    border-radius: var(--border-radius-small);
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    text-align: center;
    border: 1px solid var(--grey-light-third);

    &:focus {
      outline: none;
      border: 1px solid var(--red-danger-color);
      caret-color: transparent;
    }
  }
}